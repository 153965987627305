.last.kform--input {
  border-bottom: 1px solid var(--kindy_col_border);

  input {
    border-bottom: none;
  }
}

.kform--input {
  color: var(--kindy_col_grey3);
  background-color: #ffffff;

  ion-input {
    font-size: 15px;
    line-height: 18px;
    font-family: var(--regular-font);
    outline: none;
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    //margin-left: 16px;

    border: none;
    border-bottom: 1px solid var(--kindy_col_border);
  }

  input {
    font-size: 15px;
    line-height: 18px;
    font-family: var(--regular-font);
    outline: none;
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    margin-left: 16px;
    border: none;
    border-bottom: 1px solid var(--kindy_col_border);
  }
}

.kform-mg1 {
  margin-top: 12px;
}

.last.kform--textarea {
  border-bottom: 1px solid var(--kindy_col_border);

  ion-textarea {
    border-bottom: none;
  }
}

.border-full.kform--textarea {
  border-bottom: 1px solid var(--kindy_col_border);
}

.no-border.kform--textarea {
  ion-textarea {
    border-bottom: 0px;
  }
}

.kform--textarea {
  color: var(--kindy_col_grey3);
  background-color: #ffffff;

  ion-textarea {
    font-size: 15px;
    line-height: 18px;
    margin-left: 16px;
    min-height: 90px !important;
    padding: 6px 0;
    border-bottom: 1px solid var(--kindy_col_border);
    font-family: var(--regular-font);
  }
}

.big.kform--icon-text-arrow {
  height: 80px;
}

.first-mg.kform--icon-text-arrow {
  margin-top: 12px;
}

.last.kform--icon-text-arrow {
  .col1 {
    border-bottom: 1px solid var(--kindy_col_border);
  }
}

.kform--icon-text-arrow {
  display: table;
  width: 100%;
  background-color: #ffffff;
  // padding: 10px 0;
  cursor: pointer;

  height: 60px;

  .icon-square.col1 {
    text-align: left;
    width: 56px;
    line-height: 0px;

    img {
      width: 30px;
      height: 30px;
    }
  }

  .w2.col1 {
    width: 80px;
  }

  .col1 {
    display: table-cell;
    vertical-align: middle;
    width: 65px;
    padding-left: 16px;

    img {
      width: 40px;
      height: 40px;
    }
  }

  //.col1

  .col2 {
    display: table-cell;
    vertical-align: middle;
    padding-right: 20px;
    border-bottom: 1px solid var(--kindy_col_border);
    font-size: 15px;
    line-height: 18px;
    font-family: "VisbyRoundCF-Bold";
    color: var(--kindy-blue-dark);

    .truncate {
      max-width: 240px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }

    .reg2 {
      font-size: 16px;
      line-height: 18px;
      font-family: "VisbyRoundCF-Bold";
    }

    .reg {
      font-size: 14px;
      line-height: 18px;
      font-family: "VisbyRoundCF-Bold";
    }

    .subinfo {
      display: block;
      color: var(--kindy_col_grey2);
      font-family: var(--regular-font);
      font-size: 13px;
      line-height: 15px;
      margin-top: 3px;
    }
  }

  //.col2

  .date.col3 {
    color: var(--kindy-blue-dark);
  }

  .right.col3 {
    text-align: right;
  }

  .col3 {
    display: table-cell;
    vertical-align: middle;
    font-size: 14px;
    line-height: 14px;
    text-align: right;
    font-family: var(--regular-font);
    color: var(--kindy_col_grey2);
    border-bottom: 1px solid var(--kindy_col_border);

    .grey {
      color: var(--kindy_col_grey2);
    }

    .grey.txt {
      color: var(--kindy_col_grey2);
      font-family: "VisbyRoundCF-Bold";
    }

    .txt {
      color: var(--kindy_col_grey3);
      font-family: "VisbyRoundCF-Bold";
    }

    .blue.label {
      color: var(--kindy_col_blue);
    }

    .label {
      margin-right: 20px;
    }
  }

  //.col3

  .col4 {
    display: table-cell;
    text-align: right;
    vertical-align: middle;
    padding-right: 16px;
    width: 40px;

    .icon {
      background-image: url("data:image/svg+xml;charset=utf-8,<svg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%2012%2020'><path%20d='M2,20l-2-2l8-8L0,2l2-2l10,10L2,20z'%20fill='%23c8c7cc'/></svg>");
      background-size: 14px 14px;
      width: 14px;
      height: 14px;
      display: inline-block;
    }

    border-bottom: 1px solid var(--kindy_col_border);
  }

  //.col4
}

//.kform--icon-text-arrow

.last.kform--text-check {
  .col1 {
    border-bottom: 1px solid var(--kindy_col_border);
  }
}

.kform--text-check {
  display: table;
  width: 100%;
  background-color: #ffffff;
  height: 60px;
  cursor: pointer;

  .pic.col1 {
    display: table-cell;
    vertical-align: middle;
    width: 68px;
    padding-left: 16px;

    img {
      width: 38px;
      height: 38px;
      border-radius: 50%;
    }
  }

  .icon-square.col1 {
    display: table-cell;
    vertical-align: middle;
    width: 56px;
    padding-left: 16px;
    line-height: 0px;
    // padding-left: 16px;
  }

  .col1 {
    display: table-cell;
    width: 16px;
  }

  .noicon.col1 {
    width: 5px;
  }

  .col2 {
    display: table-cell;
    vertical-align: middle;
    font-size: 15px;
    line-height: 18px;
    color: var(--kindy_col_grey3);
    font-family: "VisbyRoundCF-Bold";
    border-bottom: 1px solid var(--kindy_col_border);

    .subinfo {
      display: block;
      color: var(--kindy_col_grey2);
      font-family: var(--regular-font);
      font-size: 13px;
      line-height: 15px;
      margin-top: 3px;
    }
  }

  .col3 {
    display: table-cell;
    text-align: right;
    vertical-align: middle;
    padding-right: 24px;
    width: 50px;
    border-bottom: 1px solid var(--kindy_col_border);
  }

  //.col4
}

//.kform--text-check

.kform--label {
  display: table;
  width: 100%;

  color: var(--kindy_col_grey3);
  font-family: "VisbyRoundCF-Bold";
  background-color: #ffffff;
  border-bottom: 1px solid var(--kindy_col_border);
  height: 60px;
  padding-left: 16px;

  .col1 {
    font-size: 15px;
    line-height: 15px;
    display: table-cell;
    vertical-align: middle;

    .subinfo {
      display: block;
      color: var(--kindy_col_grey3);
      font-family: var(--regular-font);
      font-size: 13px;
      line-height: 15px;
      margin-top: 3px;
    }
  }
}

//.kform--label

.kform.picture-multiline {
  height: auto;
  min-height: 60px;
  box-sizing: border-box;
  display: table;
  width: 100%;
  background-color: #ffffff;
  //border-bottom: 1px solid var(--kindy_col_border);
  height: 60px;
  padding-left: 16px;
}

//.kform.picture-multiline

.autoh.kform--profile {
  height: auto;
  min-height: 60px;

  .col1 {
    padding-top: 16px;
    padding-bottom: 16px;
    box-sizing: border-box;
  }

  .col2 {
    padding-right: 30px;
    font-size: 14px;
    line-height: 1.2;
  }
}

.last.kform--profile {
  .col1 {
    border-bottom: 1px solid var(--kindy_col_border);
  }
}

.kform--profile {
  display: table;
  width: 100%;
  background-color: #ffffff;
  //border-bottom: 1px solid var(--kindy_col_border);
  height: 60px;
  cursor: pointer;

  .col1 {
    display: table-cell;
    vertical-align: middle;
    width: 68px;
    padding-left: 16px;
    box-sizing: border-box;
    line-height: 0px;

    .initials {
      background-color: #00d061;
      border-radius: 50%;
      width: 38px;
      height: 38px;
      color: #ffffff;
      font-size: 18px;
      line-height: 38px;
      text-align: center;
      font-family: "VisbyRoundCF-Bold";
    }

    img {
      width: 38px;
      height: 38px;
      border-radius: 50%;
    }
  }

  .col2 {
    font-size: 15px;
    line-height: 15px;
    display: table-cell;
    vertical-align: middle;
    font-family: "VisbyRoundCF-Bold";
    color: var(--kindy_col_grey3);

    .subinfo {
      display: block;
      color: var(--kindy_col_grey2);
      font-family: var(--regular-font);
      font-size: 13px;
      line-height: 15px;
      margin-top: 5px;
    }

    border-bottom: 1px solid var(--kindy_col_border);
  }

  .col3 {
    display: table-cell;
    text-align: right;
    vertical-align: middle;
    padding-right: 16px;
    width: 40px;

    .icon {
      background-image: url("data:image/svg+xml;charset=utf-8,<svg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%2012%2020'><path%20d='M2,20l-2-2l8-8L0,2l2-2l10,10L2,20z'%20fill='%23c8c7cc'/></svg>");
      background-size: 14px 14px;
      width: 14px;
      height: 14px;
      display: inline-block;
    }

    border-bottom: 1px solid var(--kindy_col_border);
  }
}

//.kform--profile

.no-border.kform--checkcheck {
  border-bottom: none;
}

.kform--checkcheck {
  display: table;
  width: 100%;
  background-color: #ffffff;
  border-bottom: 1px solid var(--kindy_col_border);
  height: 60px;
  padding-left: 16px;

  .col1 {
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    font-size: 15px;
    line-height: 18px;
    color: var(--kindy-blue-dark);
    font-family: var(--regular-font);

    .txt-titel {
      font-size: 16px;
      font-family: "VisbyRoundCF-Bold";
      color: var(--kindy-blue-dark);
    }
  }

  .pd16.col2 {
    padding-right: 16px;
  }

  .pdl-16.col2 {
    padding-left: 16px;
  }

  .prc60.col2 {
    width: 60%;
  }

  .col2 {
    text-align: right;
    display: table-cell;
    vertical-align: middle;
  }
}

//.kform--checkcheck

.kform--btn-clean {
  display: table;
  width: 100%;

  color: var(--kindy_col_grey3);
  font-family: "VisbyRoundCF-Bold";
  background-color: #ffffff;
  border-bottom: 1px solid var(--kindy_col_border);
  height: 60px;

  .col1 {
    font-size: 18px;
    line-height: 24px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    color: var(--kindy_col_red);
  }
}

//.kform--btn-clean

.kform--toggle {
  display: table;
  width: 100%;

  color: var(--kindy_col_grey3);
  font-family: "VisbyRoundCF-Bold";
  background-color: #ffffff;
  border-bottom: 1px solid var(--kindy_col_border);
  height: 60px;

  .col1 {
    display: table-cell;
    vertical-align: middle;
    color: var(--kindy_col_grey2);
    font-family: var(--regular-font);
    font-size: 15px;
    line-height: 18px;
    padding-left: 16px;
    color: var(--kindy_col_grey3);
  }

  .col2 {
    display: table-cell;
    vertical-align: middle;
    text-align: right;
    padding-right: 20px;
  }
}

//.kform--toggle

.pd2.kform--button {
  padding: 10px 24px 10px 24px;
}

.kform--button {
  padding: 24px 24px 10px 24px;
}

//.kform--button

.custom-modal {
  --backdrop-opacity: 1;
  //backdrop-filter: blur(3px);

  &::part(backdrop) {
    background: #000;
  }

  /*
    &::part(handle) {
        background: var(--ion-color-primary);
        width: 150px;
        height: 8px;
    }
*/

  &::part(content) {
    border-radius: 10px; // Here the border radius
    box-shadow: 0px 0px 20px 5px rgb(0 0 0 / 32%);
  }
}

.v2-list-item-container {
  padding: 0 16px;
}

//.v2-list-item-container

.v2-list-item {
  padding: 16px;
  margin-bottom: 16px;

  display: table;
  width: 100%;

  background: #f5f5f6;
  border-radius: 8px;

  .center.col1 {
    text-align: center;
    padding-right: 16px;
  }

  .col1 {
    display: table-cell;

    width: 40px;
    vertical-align: middle;

    .date-day {
      color: var(--kindy-blue-dark);
      font-size: 16px;
      font-family: "VisbyRoundCF-Bold";
    }

    .date-month {
      font-size: 14px;
      font-family: "VisbyRoundCF-Bold";
      color: #ff4c5b;
    }

    .question.icon {
      width: 24px;
      height: 24px;
      background: url(/assets/img/icon/list-question.svg) center center
        no-repeat;
    }

    .open.icon {
      width: 24px;
      height: 24px;
      background: url(/assets/img/icon/list-open.svg) center center no-repeat;
    }

    .clock.icon {
      width: 24px;
      height: 24px;
      background: url(/assets/img/icon/list-clock.svg) center center no-repeat;
    }

    .check.icon {
      width: 24px;
      height: 24px;
      background: url(/assets/img/icon/list-check.svg) center center no-repeat;
    }

    .uncheck.icon {
      width: 24px;
      height: 24px;
      background: url(/assets/img/icon/list-cross.svg) center center no-repeat;
    }

    .calendar.icon {
      width: 24px;
      height: 24px;
      background: url(/assets/img/icon/list-calendar.svg) center center
        no-repeat;
    }

    .icon {
      width: 24px;
      height: 24px;
      background: url(/assets/img/icon/list-icon.svg) center center no-repeat;
    }
  }

  .col2 {
    display: table-cell;

    vertical-align: middle;

    .title {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      font-family: "VisbyRoundCF-Bold";
      color: var(--kindy-blue-dark);
    }

    .subline {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: var(--kindy-blue-dark);
    }
  }

  .col3 {
    display: table-cell;
    vertical-align: middle;
    text-align: right;
    width: 50px;

    .label {
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      font-family: "VisbyRoundCF-Bold";
      color: #354052;
    }
  }

  .icon.col4 {
    width: 35px;
  }

  .col4 {
    display: table-cell;
    width: 30px;
    vertical-align: middle;
    text-align: right;

    .icon {
      margin-top: 5px;
      display: inline-block;
      width: 24px;
      height: 14px;
      background: url(/assets/img/icon/chevron_right.svg) center center
        no-repeat;
    }

    .icon-pen {
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url(/assets/img/icon/list-pen.svg) center center no-repeat;
    }
  }
}

//.v2-list-item

.kind-chips {
  /* chips */

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 16px 16px 16px;
  gap: 8px;
  //margin-left: 16px;
  //margin-right: 16px;

  overflow-x: auto;

  /* Inside auto layout */

  // overwrite inline styles
  --offset-bottom: auto !important;
  --overflow: hidden;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.active.kindy-chip {
  background: #00b3fc;
  color: #ffffff;
}

.kindy-chip-title {
  padding: 16px 16px 0px 16px;
  font-size: 20px;
  line-height: 24px;
  color: #354052;
  font-family: "VisbyRoundCF-Bold";
}

.kindy-chip-subtitle {
  padding: 5px 16px 0 16px;
  font-size: 14px;
}

.kindy-chip {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 16px 8px 16px;
  gap: 10px;

  cursor: pointer;

  background: #eef2fe;
  border-radius: 17px;
  color: #354052;

  flex: none;
  order: 0;
  flex-grow: 0;

  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  font-family: "VisbyRoundCF-Bold";
}

.kindy-fab-button {
  background-color: #00b3fc; /* Hintergrundfarbe */
  border: none;
  color: white;
  padding: 8px 16px 8px 12px; /* Innere Abstände */
  display: inline-flex; /* Verwendet Flexbox für das Layout */
  align-items: center; /* Zentriert die Items vertikal */
  justify-content: center; /* Zentriert die Items horizontal */
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 100px; /* Abgerundete Ecken */
  font-size: 16px !important;
  font-weight: 700;
  line-height: 16px;
  font-family: "VisbyRoundCF-Bold";
  box-shadow: 3px 6px 6px 0px rgba(0, 0, 0, 0.24);

  .button-icon {
    margin-right: 8px; /* Abstand zwischen Icon und Text */
    width: 24px; /* Breite des Icons */
    height: 24px; /* Höhe des Icons */
    display: flex;
  }
}
