/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.ion-color-kindyblue {
  --ion-color-base: var(--ion-color-kindyblue) !important;
  --ion-color-base-rgb: var(--ion-color-kindyblue-rgb) !important;
  --ion-color-contrast: var(--ion-color-kindyblue-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-kindyblue-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-kindyblue-shade) !important;
  --ion-color-tint: var(--ion-color-kindyblue-tint) !important;
}

.ion-color-kindyred {
  --ion-color-base: var(--ion-color-kindyred) !important;
  --ion-color-base-rgb: var(--ion-color-kindyred-rgb) !important;
  --ion-color-contrast: var(--ion-color-kindyred-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-kindyred-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-kindyred-shade) !important;
  --ion-color-tint: var(--ion-color-kindyred-tint) !important;
}

//

@mixin sysfont() {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu !important;
}

@mixin font-regular {
  font-family: var(--regular-font);
  font-weight: 400;
}

@mixin font-bold {
  font-family: "VisbyRoundCF-Bold";
  font-weight: 700;
}

@mixin font-size($size, $lh: 161.8%) {
  font-size: $size;
  line-height: $lh;
}

.action-sheet-button {
  font-family: "VisbyRoundCF-Bold" !important;
}

.mgt40.kindy-icon-footer {
  margin-top: 40px;
}

.kindy-icon-footer {
  width: 170px;
  height: 17px;
  //background-color: red;
  margin: 20px auto 60px auto;
  //background: url(/assets/img/icon.svg) center center no-repeat;
  //background-size: 170px 17px;
  display: block;
}

.mg-16-tb {
  margin: 16px 0 16px 0;
}

.pd-16-full-nob {
  padding: 16px 16px 0 16px;
}

.pd-16-full {
  padding: 16px;
}

.pd-16-lr {
  padding: 0 16px;
}

.pd-16-tb {
  padding: 16px 0;
}

.pd-16-lrb {
  padding: 0 16px 16px 16px;
}

.kindy-icon-list {
  width: 20px;
  height: 20px;
  margin-left: 16px !important;
}

.pd12-lr.kindy-row {
  padding: 0 16px;
}

.md-top.kindy-row {
  margin-top: 16px;
}

/*
  .border-bottom.kindy-row {
      border-bottom: 1px solid var(--kindy_col_border);
  }
  */

.card-line {
  height: 1px;
  background-color: var(--kindy_col_border);
  margin: 0 16px 15px 16px;
}

.pd12.kindy-row {
  padding: 16px;
}

.full.kindy-row {
  width: 100%;
}

.kindy-row {
  display: table;

  .pd5.kindy-col {
    padding-right: 5px;
  }

  .pd10.kindy-col {
    padding-right: 10px;
  }

  .pd20.kindy-col {
    padding-right: 16px;
  }

  .line0.kindy-col {
    line-height: 0px;
  }

  .middle.kindy-col {
    vertical-align: middle;
  }

  .middle.kindy-col {
    vertical-align: middle;
  }

  .kindy-col {
    display: table-cell;
    vertical-align: top;
  }
} //.kindy-row

.lcase {
  text-transform: none;
}

.sm.kindy-avatar {
  width: 32px;
  height: 32px;
}

.kindy-avatar {
  border-radius: 50%;
  overflow: hidden;
  width: 36px;
  height: 36px;
}

.mg-bottom.kindy-btn-full {
  margin-bottom: 24px;
}

.outline.kindy-btn-full {
  outline: none;
  border: 1px solid var(--kindy_col_border);
  background-color: #ffffff;
  color: var(--kindy_col_grey3);
}

.red-outline.kindy-btn-full {
  background-color: transparent;
  border: none;
  color: var(--kindy_col_red);
}

.red.kindy-btn-full {
  background-color: var(--kindy_col_red);
  border: 1px solid var(--kindy_col_red);
}

.lightblue.kindy-btn-full {
  background: #eef2fe;
  border: 1px solid #eef2fe;
  color: var(--kindy_col_blue);
}

.b6.kindy-btn-full {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  width: auto;
  padding: 8px 10px 10px 10px;
}

.kindy-btn-full {
  outline: none;
  border: 1px solid var(--kindy_col_blue);
  background-color: var(--kindy_col_blue);
  color: #ffffff;
  @include font-size(16px, 19px);
  @include font-bold;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  width: 100%;
  text-align: center;
  padding: 12px 0 14px 0;
}

.col-row.kindy-btn-small {
  display: table;
}

.blue.kindy-btn-small {
  border: 1px solid var(--kindy_col_blue);
  background-color: var(--kindy_col_blue);
  color: #ffffff;
}

.sm.kindy-btn-small {
  @include font-size(13px, 19px);
}

.kindy-btn-small {
  outline: none;
  border: 1px solid #dfe3e9;
  -webkit-border-radius: 18px !important;
  -moz-border-radius: 18px !important;
  border-radius: 18px !important;
  padding: 8px 18px;
  background: none;
  @include font-size(15px, 18px);
  @include font-bold;
  color: var(--kindy_col_grey3);

  .col-icon {
    width: 20px;
    display: table-cell;
    vertical-align: middle;
    margin-right: 5px;

    img {
      width: 12px;
      height: 12px;
    }
  }

  .col-label {
    display: table-cell;
    vertical-align: middle;
    padding-left: 5px;
  }
} //.kindy-btn-small

.pdb.card-headline {
  padding-bottom: 11px;
}

.list.card-headline {
  margin-bottom: 10px;
}

.pd22.card-headline {
  padding: 22px 16px 22px 16px;
}

.pd-b-22.card-headline {
  padding: 11px 16px 22px 16px;
}

.card-headline {
  color: var(--kindy_col_grey2);
  font-size: 15px;
  line-height: 18px;
  @include font-bold;
  padding: 12px 16px 12px 16px;
} //.card-headline

.pd22.card-header-info {
  padding: 22px 16px 22px 16px;
}

.card-header-info {
  color: var(--kindy_col_grey2);
  font-size: 14px;
  line-height: 18px;
  @include font-regular;
  padding: 12px 16px 12px 16px;
}

.pdb.row-translate {
  margin-bottom: 10px;
  margin-top: 5px;
}

.row-translate {
  margin-top: 20px;
  cursor: pointer;

  display: table;
  .row-translate-icon {
    display: table-cell;
    vertical-align: middle;
    padding-top: 1px;
    line-height: 0px;
  }
  .row-translate-text {
    display: table-cell;
    vertical-align: middle;
    font-size: 13px;
    line-height: 15px;
    color: var(--kindy_col_blue);
    padding-left: 5px;
    font-family: "VisbyRoundCF-Bold";
  }
} // row-translate

.my-custom-class {
  --max-width: 250px;
  --background: #354053;
  --color: #ffffff;
  font-family: "VisbyRoundCF-Bold";
  font-size: 16px;
  line-height: 21px;
  text-align: center;
}

.bgwhite {
  --ion-background-color: #fff !important;
}

.emptystates-container {
  text-align: center;

  ion-grid {
    height: 100%;
  }

  ion-row {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .neuigkeiten.empty-state-icon {
    width: 192px;
    height: 192px;
    background: url("/assets/img/emptystates/emptystate-neuigkeitn.svg");
    background-size: 192px 192px;
    display: inline-block;
  }

  .nachrichten.empty-state-icon {
    width: 192px;
    height: 192px;
    background: url("/assets/img/emptystates/nachrichten.svg");
    background-size: 192px 192px;
    display: inline-block;
  }

  .dateien.empty-state-icon {
    width: 192px;
    height: 192px;
    background: url("/assets/img/emptystates/dateien.svg");
    background-size: 192px 192px;
    display: inline-block;
  }

  .archiv.empty-state-icon {
    width: 192px;
    height: 192px;
    background: url("/assets/img/emptystates/archiv.svg");
    background-size: 192px 192px;
    display: inline-block;
  }

  .account.empty-state-icon {
    width: 192px;
    height: 192px;
    background: url("/assets/img/emptystates/emptystate-account.svg");
    background-size: 192px 192px;
    display: inline-block;
  }

  .listen.empty-state-icon {
    width: 192px;
    height: 192px;
    background: url("/assets/img/emptystates/listen.svg");
    background-size: 192px 192px;
    display: inline-block;
  }

  .foto.empty-state-icon {
    width: 192px;
    height: 192px;
    background: url("/assets/img/emptystates/foto.svg");
    background-size: 192px 192px;
    display: inline-block;
  }

  .notification.empty-state-icon {
    width: 192px;
    height: 192px;
    background: url("/assets/img/emptystates/notification.svg");
    background-size: 192px 192px;
    display: inline-block;
  }

  .termine.empty-state-icon {
    width: 192px;
    height: 192px;
    background: url("/assets/img/emptystates/termine.svg");
    background-size: 192px 192px;
    display: inline-block;
  }

  .tagesverlauf.empty-state-icon {
    width: 192px;
    height: 192px;
    background: url("/assets/img/emptystates/tagesverlauf.svg");
    background-size: 192px 192px;
    display: inline-block;
  }

  .other.empty-state-icon {
    width: 192px;
    height: 192px;
    background: url("/assets/img/emptystates/error.svg");
    background-size: 192px 192px;
    display: inline-block;
  }

  .ballon.empty-state-icon {
    width: 135px;
    height: 135px;
    background: url("/assets/img/emptystates/ballon.svg");
    background-size: 135px 135px;
    display: inline-block;
  }

  .zeiten.empty-state-icon {
    width: 135px;
    height: 135px;
    background: url("/assets/img/emptystates/zeiten.svg");
    background-size: 135px 135px;
    display: inline-block;
  }

  .archive-small.empty-state-icon {
    width: 135px;
    height: 135px;
    background: url("/assets/img/emptystates/archive-small.svg");
    background-size: 135px 135px;
    display: inline-block;
  }

  .shortmessage-small.empty-state-icon {
    width: 135px;
    height: 135px;
    background: url("/assets/img/emptystates/shortmessage-small.svg");
    background-size: 135px 135px;
    display: inline-block;
  }

  .postbox-small.empty-state-icon {
    width: 135px;
    height: 135px;
    background: url("/assets/img/emptystates/postbox-small.svg");
    background-size: 135px 135px;
    display: inline-block;
  }

  h1 {
    font-family: VisbyRoundCF-Bold;
    font-size: 26px;
    text-align: center;
    margin-top: 45px;
    line-height: 31px;
    color: var(--ion-card-headline-color);
  }

  .small-h {
    font-size: 20px;
    margin-top: 20px;
    line-height: 22px;
  }

  p {
    font-family: VisbyRoundCF-Medium;
    font-size: 14px;
    text-align: center;
    line-height: 18px;
    margin-bottom: 40px;
    margin-top: 5px;
    padding: 0 20px;
    color: var(--kindy_col_grey2);
  }
} //.emptystates-container

.preprepre {
  white-space: pre-line;
}

.selectext {
  -webkit-user-select: auto;
}

.linkified {
  color: rgb(53, 64, 82);
}

.btn-text-dark {
  color: black !important;
}

.message-headline {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.col1.query-list-icon {
  background-color: #00b3fc !important;
}
.col2.query-list-icon {
  background-color: #fe7be3 !important;
}
.col3.query-list-icon {
  background-color: #00d061 !important;
}
.col4.query-list-icon {
  background-color: #a288f3 !important;
}

.n41.query-list-icon {
  background: url("/assets/img/lists/icon-41.svg") #00b4ff center center
    no-repeat;
}
.n40.query-list-icon {
  background: url("/assets/img/lists/icon-40.svg") #00b4ff center center
    no-repeat;
}
.n39.query-list-icon {
  background: url("/assets/img/lists/icon-39.svg") #00b4ff center center
    no-repeat;
}
.n38.query-list-icon {
  background: url("/assets/img/lists/icon-38.svg") #00b4ff center center
    no-repeat;
}
.n37.query-list-icon {
  background: url("/assets/img/lists/icon-37.svg") #00b4ff center center
    no-repeat;
}
.n36.query-list-icon {
  background: url("/assets/img/lists/icon-36.svg") #00b4ff center center
    no-repeat;
}
.n35.query-list-icon {
  background: url("/assets/img/lists/icon-35.svg") #00b4ff center center
    no-repeat;
}
.n34.query-list-icon {
  background: url("/assets/img/lists/icon-34.svg") #00b4ff center center
    no-repeat;
}
.n33.query-list-icon {
  background: url("/assets/img/lists/icon-33.svg") #00b4ff center center
    no-repeat;
}
.n32.query-list-icon {
  background: url("/assets/img/lists/icon-32.svg") #00b4ff center center
    no-repeat;
}
.n31.query-list-icon {
  background: url("/assets/img/lists/icon-31.svg") #00b4ff center center
    no-repeat;
}
.n30.query-list-icon {
  background: url("/assets/img/lists/icon-30.svg") #00b4ff center center
    no-repeat;
}
.n29.query-list-icon {
  background: url("/assets/img/lists/icon-29.svg") #00b4ff center center
    no-repeat;
}
.n28.query-list-icon {
  background: url("/assets/img/lists/icon-28.svg") #00b4ff center center
    no-repeat;
}
.n27.query-list-icon {
  background: url("/assets/img/lists/icon-27.svg") #00b4ff center center
    no-repeat;
}
.n26.query-list-icon {
  background: url("/assets/img/lists/icon-26.svg") #00b4ff center center
    no-repeat;
}
.n25.query-list-icon {
  background: url("/assets/img/lists/icon-25.svg") #00b4ff center center
    no-repeat;
}
.n24.query-list-icon {
  background: url("/assets/img/lists/icon-24.svg") #00b4ff center center
    no-repeat;
}
.n23.query-list-icon {
  background: url("/assets/img/lists/icon-23.svg") #00b4ff center center
    no-repeat;
}
.n22.query-list-icon {
  background: url("/assets/img/lists/icon-22.svg") #00b4ff center center
    no-repeat;
}
.n21.query-list-icon {
  background: url("/assets/img/lists/icon-21.svg") #00b4ff center center
    no-repeat;
}
.n20.query-list-icon {
  background: url("/assets/img/lists/icon-20.svg") #00b4ff center center
    no-repeat;
}
.n19.query-list-icon {
  background: url("/assets/img/lists/icon-19.svg") #00b4ff center center
    no-repeat;
}
.n18.query-list-icon {
  background: url("/assets/img/lists/icon-18.svg") #00b4ff center center
    no-repeat;
}
.n17.query-list-icon {
  background: url("/assets/img/lists/icon-17.svg") #00b4ff center center
    no-repeat;
}
.n16.query-list-icon {
  background: url("/assets/img/lists/icon-16.svg") #00b4ff center center
    no-repeat;
}
.n15.query-list-icon {
  background: url("/assets/img/lists/icon-15.svg") #00b4ff center center
    no-repeat;
}
.n14.query-list-icon {
  background: url("/assets/img/lists/icon-14.svg") #00b4ff center center
    no-repeat;
}
.n13.query-list-icon {
  background: url("/assets/img/lists/icon-13.svg") #00b4ff center center
    no-repeat;
}
.n12.query-list-icon {
  background: url("/assets/img/lists/icon-12.svg") #00b4ff center center
    no-repeat;
}
.n11.query-list-icon {
  background: url("/assets/img/lists/icon-11.svg") #00b4ff center center
    no-repeat;
}
.n10.query-list-icon {
  background: url("/assets/img/lists/icon-10.svg") #00b4ff center center
    no-repeat;
}
.n9.query-list-icon {
  background: url("/assets/img/lists/icon-9.svg") #00b4ff center center
    no-repeat;
}
.n8.query-list-icon {
  background: url("/assets/img/lists/icon-8.svg") #00b4ff center center
    no-repeat;
}
.n7.query-list-icon {
  background: url("/assets/img/lists/icon-7.svg") #00b4ff center center
    no-repeat;
}
.n6.query-list-icon {
  background: url("/assets/img/lists/icon-6.svg") #00b4ff center center
    no-repeat;
}
.n5.query-list-icon {
  background: url("/assets/img/lists/icon-5.svg") #00b4ff center center
    no-repeat;
}
.n4.query-list-icon {
  background: url("/assets/img/lists/icon-4.svg") #00b4ff center center
    no-repeat;
}
.n3.query-list-icon {
  background: url("/assets/img/lists/icon-3.svg") #00b4ff center center
    no-repeat;
}
.n2.query-list-icon {
  background: url("/assets/img/lists/icon-2.svg") #00b4ff center center
    no-repeat;
}

.query-list-icon {
  width: 40px;
  height: 40px;
  background: url("/assets/img/lists/icon-1.svg") #00b4ff center center
    no-repeat;
  border-radius: 50%;
}

@media only screen and (min-width: 700px) {
  body {
    background: #c5eafd; /* Old browsers */
    background: -moz-linear-gradient(
      top,
      #c5eafd 0%,
      #feffff 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      #c5eafd 0%,
      #feffff 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      #c5eafd 0%,
      #feffff 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c5eafd', endColorstr='#feffff',GradientType=0 ); /* IE6-9 */
  }
}
